import React, { Component } from 'react';
// import Link from '../Link';
// use  Link + Router to set up a virtual router provided by react !
import { Link, Outlet } from 'react-router-dom'; // must install,  npm install react-router-dom
// <Link> looks like it’s trying to access the context of the Router, and isn’t finding it. 
// error react__WEBPACK_IMPORTED_MODULE_0__.useContext(...) is null
// must make sure each link has corresponding Route
import Search from './search.js';

import CompanyInfo from '../about/CompanyInfo.js';


// language option
import {lang_, lang_s_, dict_, lang_to_server_} from '../language';

class Layout extends Component {

    // change the state, according to the response json
    change_state(json) {
        if ("action" in json) { // this return from a post request
            switch (json["action"]) {
                case "check_login":
                    //alert("check_login__layout__"+json["imgurl"]); // name not match ...
                    const imageURL =  "/user_img/"+json["user_id"];
                    this.setState({ isLoggedIn: json["ifsuccess"],
                        user_id: json["user_id"],
                        imgURL: imageURL,//json["imgurl"]
                    });

                    /*
                    // check image....implemented it in the img_c much more easier !!
                    check_exist(imageURL).then((exist) => {
                        // alert("exists__"+exist+"__"+imageURL);
                        if (exist && (exist != "html")){ // image
                            // this.setState({imgURL:imageURL});
                            // alert("will "+imageURL);
                        } else {
                            this.setState({imgURL:"/user_img/images_default.jpg",})
                        }
                    });
                    */

                    break;
                case "logout":
                    break;
            }
        } 
    }
    // state change, the render will automatically run

    //sets the initial logged in state
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false,//(null != firebase.auth().currentUser),
            recruiter: false,
            // imgURL: "images_default.jpg",
            requests: []
            // ,page: <SessionUser />
        };

        // must bind change_state function to make this.change_state able to execute
        // this.change_state = this.change_state.bind(this);
    }

    componentWillMount() {
        // check_login(this.change_state);

        // if not login page
        var href = window.location.href.split("/");
        href = href[href.length-1];

        // already login page
        if (true){ 
			// connect to database
			const json = {
				"action": "account",
				"ops": "info"
			};
	
			const formData = new URLSearchParams();
			for (const key in json) {
				formData.append(key, json[key]);
			}
			fetch("/action/", {
				method: "POST",
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: formData // must stringify
			})
				.then((response) => response.json())
				.then((data) => {
					// console.log(data);
					console.log({...json, ...data});

					if (("ifsuccess" in data) && (data["ifsuccess"]=="true")){
						// add to UI
						this.setState({...data,
                            isLoggedIn: true});
                        
                        if (href == "login"){
                            alert(dict_["you logged in"]);
                            window.location.assign('/');
                        }
					} else {
                        if (href != "login"){
                            alert(dict_["you need to log in"]);
                            window.location.assign('/login');
                        } 
                    }
				})
				.catch((error) => {
					console.error("Error fetch education:", error);
				});
			
		}
    }

    handle_lang_change(lang){
        if (lang_ != lang){
            // reload --> !==null --> remote server
            localStorage.setItem('lang_', lang);
            window.location.reload();
        } else {
            // same--> already done
        }
    }

    // state change, the render will automatically run
    render() {
        var loginOrOut;
        var signUp;
        var accountSettings;
        var talking;
        var email;

        var div;

        var divStyle = {
            fontSize: '10px',
            textAlign: 'center',
            color: 'white',
            width: '15px',
            height: '15px',
            position: 'relative',
            backgroundColor: 'red',
            borderRadius: '5px',
            top: '-30px',
            right: '-10px',
            zIndex: '1'
        }


        if(this.state.requests.length > 0){
            div = <div style={divStyle}>{this.state.requests.length}</div>
        }else{
            div= null;
        }


        //if the user is logged in, show the logout and profile link
        // console.log(this.state.isLoggedIn);
        // alert("layout_render__"+this.state.imgURL);
        // change link to a, to force users/ refresh to reload to another's data...otherwise photo remain the same
        // since they are actually different page
        if (this.state.isLoggedIn) {
            loginOrOut = <li><Link to="/logout" className="navbar-brand"><span className="glyphicon glyphicon-off navbar-icon" title={dict_["log out"]}></span></Link></li>;
            signUp = null;

            {/*icon reference:  https://getbootstrap.com/docs/3.4/components/#glyphicons*/}
            {/*in index.html:  <link rel="stylesheet" href="/bootstrap-3.3.7-dist/css/bootstrap.min.css">*/}
            {/*https://getbootstrap.com/docs/3.3/components/*/}
            accountSettings = <li><Link to="/accountSettings" className="navbar-brand"><span className="glyphicon glyphicon-cog navbar-icon" title={dict_["setting"]}></span></Link></li>;
            
            talking = <li><Link to="" className="navbar-brand"><span className="glyphicon glyphicon-question-sign navbar-icon" title={dict_["taling"]}></span></Link></li>;
            email = <li><Link to="/email" className="navbar-brand"><span className="glyphicon glyphicon-envelope navbar-icon" title={dict_["email"]}></span></Link></li>;
            
        } else { //if the user is not logged in, show the login and signup links
            loginOrOut = <li><Link to="/login" className="navbar-brand">{dict_["log in"]}</Link></li>;
            signUp = <li><Link to="/signup" className="navbar-brand">{dict_["log out"]}</Link></li>;
            accountSettings = null;
        }

        /*
        //if recruiter -> black navbar, else job seeker -> default navbar
        if (this.state.recruiter == true) {
            navClassName = "navbar navbar-inverse navbar-static-top";
        } else {
            navClassName = "navbar navbar-default navbar-static-top";
        }
        */


        return (
            <span data-reactroot="">
                {/*<nav className="navbar navbar-default navbar-static-top" >  change it in style.css*/}
                <nav className="navbar navbar-default">
                    <div className="container-fluid">
                        
                        {/* default shown in the left*/}
                        <div className="navbar-header">
                            {/* after collapse*/}
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            
                        {/*    <a className="navbar-brand" href="#">Brandxx</a>
                        */}
                        </div>
                        

                        {/*before collapse*/}
                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            {/*
                            <ul class="nav navbar-nav">
                                <li class="active"><a href="#">Link <span class="sr-only">(current)</span></a></li>
                                <li><a href="#">Link</a></li>
                                <li class="dropdown">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Dropdown <span class="caret"></span></a>
                                <ul class="dropdown-menu">
                                    <li><a href="#">Action</a></li>
                                    <li><a href="#">Another action</a></li>
                                    <li><a href="#">Something else here</a></li>
                                    <li role="separator" class="divider"></li>
                                    <li><a href="#">Separated link</a></li>
                                    <li role="separator" class="divider"></li>
                                    <li><a href="#">One more separated link</a></li>
                                </ul>
                                </li>
                            </ul>
                            <form class="navbar-form navbar-left">
                                <div class="form-group">
                                <input type="text" class="form-control" placeholder="Search"/>
                                </div>
                                <button type="submit" class="btn btn-default">Submit</button>
                            </form>
                            */}

                            <ul className="nav navbar-nav navbar-right">
                                {/*<li><a href="#">Link</a></li>*/}
                                <li className="dropdown">
                                <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                    {lang_} 
                                    <span className="caret"></span>
                                </a>
                                <ul className="dropdown-menu">
                                    {/*react need a key*/}
                                    {lang_s_.map((lang, index) => (
                                        <li key={index} onClick={this.handle_lang_change.bind(this,lang)}><a color='black'>{lang}</a></li>
                                    ))}
                                    {/*
                                    <li role="separator" class="divider"></li>
                                    <li><a href="#">Separated link</a></li>
                                    */}
                                </ul>
                                </li>
                            </ul>

                            <div className="container">
                                <ul className="nav navbar-nav pull-right">
                                    {talking}
                                    {email}
                                    {signUp} {/*shows only if user is not logged in*/}
                                    {accountSettings}
                                    {loginOrOut} {/*shows login or logout link depending on logged in state*/}
                                    
                                </ul>
                            </div>
                        
                        </div>
                    </div>
                </nav>


                {/*shows the rest of the page: home, login, signup, etc. */}
                {/*window.location.pathname=="/email" ? can't use this, will not render update automatically*/}
                {/*className="container" ... must vary in different Outlet component*/}
                <div style={{ width: window.innerWidth-6, height: window.innerHeight }}>
                    {/*this.props.children // maybe I should use use this as main*/}
                    {/* here must use Outlet for nested Route, otherwise, this.props.children not work*/}
                    <Outlet/>
                </div>

                {(window.location.pathname == '/login') ?
                    (<nav class="navbar navbar-default navbar-fixed-bottom">
                        <div class="alert alert-info" role="alert">
                            <CompanyInfo 
                            companyName={'EmailHelp, Inc.'}
                            location={'LA JOLLA, CA 92093'}
                            street={'3869 MIRAMAR ST'}
                            contact={'theandsea@outlook.com'}
                            website={'www.emailhelp.top'}
                            />
                        </div>
                    </nav>)
                    :
                    <div></div>}
            </span>
        )
    }
}


export default Layout;
