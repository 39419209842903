// import { maxHeight } from '@mui/system';
// import { fireEvent } from '@testing-library/react';
import React, { Component } from 'react';
// import { Link, redirect } from 'react-router-dom';

// import Imgc from '../imgc';



// to show code like chatgpt
// reference in :  https://github.com/react-syntax-highlighter/react-syntax-highlighter
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import docco from 'react-syntax-highlighter/dist/esm/styles/hljs/docco';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Choose a style (e.g., darcula)

/*
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
*/


// language option
import {dict_} from '../language';


import { isMobile } from 'react-device-detect';

// must put import ahead
// let cache = { status: "Good", friend_active_th: -1 };

class Talking extends Component {
    constructor(props) {
        super(props);

        /**/
        this.state = {
            talking_list: [],
            talking_active_th: -1,
            talking_edit_th: -1,
            dialog_sum: {}, // talking_id -- message 
            th_id: 0, // current active friend's th, default is 0 (first)
            talking_id: -1, // current active talking's id
            username: "", // current active friend's user name 
            self_id: -1, // his own id ?
            socket_list: null,
            number: 0
        };


        this.chat_box = React.createRef();
        this.sending_message = React.createRef();
        this.sending_button = React.createRef();
        this.edit_box = React.createRef();

        this.sending = false; // state of sending
    }

    initial_setting() {
        const json = {
            "action": "talking",
            "ops": "load_talking",
            // "user_id": -1 // has to pass a value...not used at all
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                    this.setState({ talking_list: data["talking_list"], self_id: data["self_id"] });
                }

                // establish the socket for each friend
                /*
                var talking_list = data["talking_list"];
                // add message_  
                for (let i = 0; i < friends.length; i++) {
                    var msg_name = "messages_" + friends[i]["user_id"];
                    this.setState({ [msg_name]: [] });
                }

                
                var self_id = data["self_id"];
                var socket_list = [];
                for (let i = 0; i < friends.length; i++) {
                    var user_id = friends[i]["user_id"];
                    // 'ws://your-domain/ws/chat/room-name/'
                    // must use absolute url, or "An invalid or illegal string was specified"
                    // thus, have to modify it manually 
                    const socket_url = "ws://" + window.location.hostname + ':8000/chatroom/' + Math.min(user_id, self_id) + "_" + Math.max(user_id, self_id) + "/";

                    console.log(socket_url);
                    // maybe due to re-render...has several websocket, thus double receiving
                    const newSocket = new WebSocket(socket_url); // , 'echo-protocol'  
                    socket_list.push(newSocket); // unshift for start

                    // listen to the incoming message
                    newSocket.onmessage = async (event) => {
                        try {
                            const data = JSON.parse(event.data);
                            if ("user_id" in data) {
                                // receive and add to the chat list
                                const l = this.state.messages.length;
                                // check if identical to avoid repeated websocket since inevitable in react
                                if (l == 0 || data.created_at != this.state.messages[l - 1].created_at) {
                                    // no him self
                                    if (data.user_id != this.state.self_id) {
                                        // console.log("receive new !");
                                        // var messages = this.state.messages;
                                        // this.setState({ messages: [...messages, { ...data, user_id: this.state.user_id }] });
                                        var msg_name = "messages_" + data.user_id;
                                        this.setState({ [msg_name]: [...this.state[msg_name], data] },
                                            () =>{ // scroll down for current
                                                if (data.user_id == this.state.user_id && this.chat_box.current) {
                                                    // scroll the chat
                                                    const chat_box = this.chat_box.current;
                                                    chat_box.scrollTop = chat_box.scrollHeight;
                                                }
                                            });
                                    } else { // sent message
                                        // must get the time from server
                                        var msg_name = "messages_" + this.state.user_id;
                                        
                                        // alert(msg_name);
                                        // console.log(msg_name);
                                        // console.log(this.state);
                                        // console.log(this.state[msg_name])
                                        
                                        this.setState({ [msg_name]: [...this.state[msg_name], data] }, 
                                            () => { // scroll down for current
                                                if (this.chat_box.current) {
                                                    // scroll the chat
                                                    const chat_box = this.chat_box.current;
                                                    chat_box.scrollTop = chat_box.scrollHeight;
                                                }
                                            });
                                    }

                                } else {
                                    // alert("blocked");
                                }
                            } else if (("special_type" in data) && (data["special_type"] == "history")) {
                                // for load history
                                this.setState(data);
                                console.log(data);
                            }
                        } catch (error) {
                            console.error("Error handling socket message:", error);
                        }

                    };

                    newSocket.onclose = function (e) {
                        // this.setState({status:"bad"});
                        cache["status"] = "bad";
                        console.error('Chat socket closed unexpectedly');
                    };
                }
                // alert(socket_list.length);
                this.setState({ socket_list: socket_list, friend_active_th: cache.th });
                
                cache["status"] = "Good";
                */
            })
            .catch((error) => {
                console.error("Error fetch talking:", error);
            });
    }

    componentWillMount() {
        this.initial_setting();
    }



    /*
    incrementNumber() {
        // this.setState(prevState => ({ number: prevState.number + 1 })); // Increment the number by 1
        // this.setState({ number: this.state.number + 1 });
        if (cache["status"] == "bad") { // disconnected --> reconnect again
            cache["th"] = this.friend_active_th;
            this.initial_setting();
            // why active_friend_th do not get maintained ??? also reset ???
        }
    }

    componentDidMount() {
        // pageshow,  visibilitychange not work
        // window.addEventListener('visibilitychange', this.handleFocus.bind(this));

        // Call the incrementNumber function every second
        const intervalId = setInterval(this.incrementNumber.bind(this), 2000); // 1000 milliseconds = 1 second
    }
    

    componentWillUnmount() {
        if (this.state.socket_list) {
            // alert("close !");
            for (let i = 0; i < this.state.socket_list.length; i++)
                this.state.socket_list[i].close();
            this.setState({ socket_list: null });
        }
    }
    */



    handle_sending() {
        // e.target.value
        // send the message
        if (this.state.talking_id!=-1 && (!this.sending)){
            // disable it
            this.sending = true;
            this.sending_button.current.disabled = true;
            this.sending_message.current.disabled = true;

            const json = {
                "action": "talking",
                "ops": "add_dialog",
                // "user_id": this.state.user_id,
                // "username": this.state.username,
                "human": true,
                "talking_id": this.state.talking_id, 
                "content": this.sending_message.current.value
            };

            const formData = new URLSearchParams();
            for (const key in json) {
                formData.append(key, json[key]);
            }
            fetch("/action/", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData // must stringify
            })
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    console.log({ ...json, ...data });
                    if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                        // update UI
                        var dialog_list = this.state.dialog_sum[this.state.talking_id];
                        // add to the list, human, ai reponse, respectively
                        console.log(this.state.talking_id);                 
                        dialog_list.push(json);
                        dialog_list.push(data)
                        var dialog_sum = this.state.dialog_sum;
                        dialog_sum[this.state.talking_id] = dialog_list;

                        this.setState({dialog_sum: dialog_sum},
                            () => { // scroll_down 
                                this.chat_box_scroll_down();
                            });

                        // emptify
                        this.sending_message.current.value = "";
                        // re-enable
                        this.sending = false;
                        this.sending_button.current.disabled = false;
                        this.sending_message.current.disabled = false;
                    } else{
                        alert(data["message"]);
                    }
                })
                .catch((error) => {
                    console.error(dict_["Error sending talking: "], error);
                });
        } else if (this.sending){

        } else if (this.state.talking_id==-1){
            alert(dict_["please choose a talking"]);
        } else {
            alert(dict_["unexpected error in sending talking"]);
        }
        

        /*
        if (this.state.friend_active_th >= 0) {
            const socket = this.state.socket_list[this.state.friend_active_th];
            const sending_message = this.sending_message.current.value;
            if (socket && sending_message.trim() !== '') {
                // make sure open before sending
                if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({
                        'message': sending_message,
                        'user_id': this.state.self_id
                        // ,'created_at': new Date().toISOString()
                    }));
                } else {
                    alert("网络或服务器故障"); // 'WebSocket is not open.'
                }
                // alert("test !");
                this.sending_message.current.value = "";
                // time must be given by server
                // send and add to the chat list
                // var msg_name = "messages_" + this.state.user_id;
                // this.setState({ [msg_name]: [...this.state[msg_name], { message: sending_message, user_id: this.state.self_id }] });
            }
        } else {
            alert("请选择一个朋友!"); // "choose a friend !"
        }
        */
    }

    handleKeyPress(e) {
        if (e.key == 'Enter') {
            this.handle_sending();
        }
    }


    // separate the code from a bulk string
    string_seg(content){

    }

    ai_post(post, index) { // need to add key={index} for react tracking them
        var dateTimeCustomization = {
            year: "numeric", month: "short",
            day: "numeric", hour: "2-digit", minute: "2-digit"
        };


        var content;

        function text_split(text){ // split to
            // 
            var seg = text.split('\n\n');
            var content_seg = [];
            for (let i=0; i<seg.length; i++)
                content_seg.push({content:seg[i]});

            return content_seg;
        }

        
        content = post.content;

        /*
        console.log("content__\r\n"+content);
        console.log("total__"+seg.length);
        // for (let i=0; i<seg.length; i++)
        //     console.log(seg[i]);
        console.log("0__\r\n"+seg[0]);
        var content_seg = text_split(seg[0]); //[{content:seg[0]}];
        for(let i=1;i<seg.length;i++){
            var first = seg[i].indexOf("\n");
            var language;
            if(first > 0){
                language = seg[i].substring(0,first);
            } else { // bash is defalut ???....''' is python !
                // default is not sure, just leave it empty (not code style)
                // azure open ai is not sure
                language = "";//"bash"; 
            }
            var last = seg[i].lastIndexOf("```");
            var code, rest;
            code = seg[i].substring(first+1,last);
            rest = seg[i].substring(last+3);
            console.log(i+"__\r\n"+seg[i]);
            console.log("break____0\r\n"+code+"\r\n"+"break____1\r\n"+rest);
            content_seg.push({content:code,language:language});
            content_seg.push(...text_split(rest)) ;//({content:rest});
        }
        console.log(content_seg);
        */

        // \n\n'''   \n\n```    different !!
        // not necessarily \n```  maybe \n     ```javascript
        var seg = content.split("```"); // just start and end with \n```
        var content_seg = [];
        for(let i=0;i<seg.length;i++){
            if(i % 2 == 1){ // code
                var first = seg[i].indexOf("\n");
                var language;
                if(first > 0){
                    language = seg[i].substring(0,first);
                } else { // bash is defalut ???....''' is python !
                    // default is not sure, just leave it empty (not code style)
                    // azure open ai is not sure
                    language = "";//"bash"; 
                }
                var code = seg[i].substring(first+1);
                content_seg.push({content:code,language:language});
            } else { // text
                content_seg.push(...text_split(seg[i])) ;//({content:rest});
            }
        }



        return <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <table>
                <tbody>
                    {/*<tr>
                        <td style={{ padding: '0 0 0 5px', color: '#9f9f9f', verticalAlign: 'top' }}>
                            {(new Date(post.created_at)).toLocaleTimeString("zh-CN", dateTimeCustomization)}
                        </td>
                    </tr>*/}

                    <tr>
                        <td rowSpan='2' style={{ padding: '0 5px 0 0' }}>
                            <img src="ai.jpg" width="80" height="80" style={{ objectFit: 'cover' }} />
                        </td>
                    </tr>

                </tbody>
            </table>
            <div style={{ backgroundColor: "white", color: "blue", padding: "8px", border: "1px solid black", borderRadius: "16px" }}>
                {/*post.content
                <SyntaxHighlighter language="python" style={dark}>
                {post.content}
                </SyntaxHighlighter>
                */}
                {content_seg.map((seg, index) => (
                    seg.language ?(
                        <div key={index}>
                        <p/>
                        {/*here must use () , instead of {}; must make sure this comment is within <div> parent !*/
                        /* here must use '' for property*/}
                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', color: '#777', backgroundColor: '#f5f5f5', padding: '8px 16px', fontSize: '12px', fontFamily: 'sans-serif', justifyContent: 'space-between', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
                            {seg.language}
                        </div>
                        <SyntaxHighlighter language={seg.language} style={darcula}>
                            {seg.content}
                        </SyntaxHighlighter>
                        </div>
                        ):(
                        <div key={index}>
                        <p/>{seg.content}
                        </div>)
                ))}

                <br /> {/*received*/}
            </div>
        </div>;
    }

    self_post(post, index) {
        var dateTimeCustomization = {
            year: "numeric", month: "short",
            day: "numeric", hour: "2-digit", minute: "2-digit"
        };

        return <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <div style={{ backgroundColor: "blue", color: "white", padding: "8px", border: "1px solid white", borderRadius: "16px" }}>
                {post.content}<br /> {/*sent*/}
            </div>
            <table>
                <tbody>
                    {/*<tr>
                        <td style={{ padding: '0 0 0 5px', color: '#9f9f9f', verticalAlign: 'top' }}>
                            {(new Date(post.created_at)).toLocaleTimeString("zh-CN", dateTimeCustomization)}
                        </td>
                    </tr>*/}

                    <tr>

                        <td rowSpan='2' style={{ padding: '0 5px 0 0' }}>
                            <img src="user.jpg" width="80" height="80" style={{ objectFit: 'cover' }} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>;
    }

    handle_talking_add(index){
        const json = {
            "action": "talking",
            "ops": "add_talking",
            // "user_id": -1 // has to pass a value...not used at all
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                    var talking_list = this.state.talking_list;
                    // do not use append, not update automatically, push works
                    talking_list.push({...data});
                    this.setState({ talking_list: talking_list },
                        () => { // make sure happen after 
                            /*
                            if (this.chat_box.current) {
                                // scroll the chat
                                const chat_box = this.chat_box.current;
                                chat_box.scrollTop = chat_box.scrollHeight;
                                // scroll the whole window
                                window.scrollTo(0, document.body.scrollHeight);
                            }
                            */
                        }
                    );
                }

            })
            .catch((error) => {
                console.error(dict_["Error add a new talking"]+":", error);
            });
    }

    handle_talking_delete(index){
        const json = {
            "action": "talking",
            "talking_id": this.state.talking_list[index].id,
            "ops": "delete_talking",
            // "user_id": -1 // has to pass a value...not used at all
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                    var talking_list = this.state.talking_list;
                    // use splice to remove
                    talking_list.splice(index, 1);
                    this.setState({ talking_list: talking_list });
                }

            })
            .catch((error) => {
                console.error("Error delete a talking:", error);
            });
    }

    chat_box_scroll_down() {
        if (this.chat_box.current) {
            // scroll the chat
            const chat_box = this.chat_box.current;
            chat_box.scrollTop = chat_box.scrollHeight;
            // scroll the whole window
            window.scrollTo(0, document.body.scrollHeight);
        }
    }

    handle_talking_click(index) {
        // here must use bind to define "this", otherwise, undefined
        // talking_active.bind(this, index)();
        function talking_active(index){
            // active
            // alert(index);  //"__"+this.state.talking_list[index].id
            this.setState({
                talking_active_th: index,
                talking_id: this.state.talking_list[index].id
            },
                () => { // make sure happen after 
                    this.chat_box_scroll_down();
                }
            );
        }


        var talking_id = this.state.talking_list[index].id;

        if (talking_id in this.state.dialog_sum){ // already fetched, just show
            // active
            talking_active.bind(this, index)();
        } else { // not fetched, must first fetch it
            const json = {
                "action": "talking",
                "talking_id": talking_id,
                "ops": "load_dialog",
                // "user_id": -1 // has to pass a value...not used at all
            };
    
            const formData = new URLSearchParams();
            for (const key in json) {
                formData.append(key, json[key]);
            }
            fetch("/action/", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData // must stringify
            })
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    console.log({ ...json, ...data });
                    if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                        var talking_list = this.state.talking_list;
                        // load dialog
                        var dialog_sum = this.state.dialog_sum;
                        dialog_sum[talking_id] = data["dialog_list"];
                        
                        // add dialog list
                        this.setState({dialog_sum: dialog_sum});
                        // active
                        talking_active.bind(this, index)();
                    } else {
                        alert(data["message"])
                    }
    
                })
                .catch((error) => {
                    console.error("Error to fetch the dialog with a talking:", error);
                });
        }
    }

    handle_talking_edit_state(index){ // change to edit state....textbox
        this.setState({talking_edit_th: index});
    }

    handle_talking_edit(index){ // edit !
        this.edit_box.current.disabled = true;
        const json = {
            "action": "talking",
            "talking_id": this.state.talking_list[index].id,
            "summary": this.edit_box.current.value,
            "ops": "update_talking"
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                    var talking_list = this.state.talking_list;
                    // load dialog
                    var talking_list = this.state.talking_list;
                    talking_list[index]["summary"] = this.edit_box.current.value;
                    
                    // update state
                    this.setState({
                        talking_list: talking_list,
                        talking_edit_th: -1
                    });
                    this.edit_box.current.disabled = false;
                
                } else {
                    alert(data["message"]);
                    this.edit_box.current.disabled = false;
                }

            })
            .catch((error) => {
                console.error("Error to edit/rename the talking: ", error);
                this.edit_box.current.disabled = false;
            });
    }

    render() {
        // use chat gpt is even more convenient than forked from github...more flexible to change
        // different align:
        /*
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderBottom: '1px solid gray' }}>
        <div>Contact Details</div>
        <button type="button" className="edit_button">
            My Button
        </button>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid gray' }}>
        <div>Contact Details</div>
        <button type="button" className="edit_button">
            My Button
        </button>
        </div>
        
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', borderBottom: '1px solid gray' }}>
        <div>Contact Details</div>
        <button type="button" className="edit_button">
            My Button
        </button>
        </div>
        */

        var chat_list;
        if (this.state.talking_id >= 0) {
            chat_list = this.state.dialog_sum[this.state.talking_id];
            // alert("messages_"+this.state.user_id);
        } else
            chat_list = [];

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        {/*Friend List */}
                        {/*{this.state.number} <br /> {cache["status"]}*/}
                        <div className="friend-list">
                            <ul className="list-group">
                                {this.state.talking_list.map((talking, index) => (
                                    <li
                                    key={index}
                                    className={this.state.talking_active_th == index ? "list-group-item active" : "list-group-item"} >
                                        {this.state.talking_edit_th == index ? 
                                            <span onClick={this.handle_talking_edit.bind(this, index)} className="glyphicon glyphicon-ok" title={dict_["OK"]}/>
                                            :
                                            <span onClick={this.handle_talking_edit_state.bind(this, index)} className="glyphicon glyphicon-pencil" title={dict_["edit"]}/>}
                                        
                                        {this.state.talking_edit_th == index ? 
                                            /*here must use defaultValue, if use value then unchangeable*/
                                            <input type="text" defaultValue = {talking.summary} ref={this.edit_box}/>
                                            : 
                                            <span onClick={this.handle_talking_click.bind(this, index)}>{talking.summary} </span> }
                                        <button type="button" className="btn btn-default" aria-label="Left Align">
                                            <span onClick={this.handle_talking_delete.bind(this, index)} className="glyphicon glyphicon-minus" title={dict_["remove"]}>  </span>
                                        </button>
                                    </li>
                                ))}
                                
                                {// add option
                                    <li
                                    key={this.state.talking_list.length}
                                    className={"list-group-item"}>
                                    {dict_["add talking"]} 
                                    <button type="button" className="btn btn-default" aria-label="Left Align">
                                        <span onClick={this.handle_talking_add.bind(this, this.state.talking_list.length)} className="glyphicon glyphicon-plus" title={dict_["add"]}>  </span>
                                    </button>
                                    </li>
                                }
                                {/*<li className="list-group-item active">Friends</li>
                                <li className="list-group-item">Friend 1</li>
                                <li className="list-group-item">Friend 2</li>
                                <li className="list-group-item">Friend 3</li>
                                 Add more friends as list items */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        {/* Chat List */}
                        <div className="chat-box" ref={this.chat_box}>
                            <div className="chat-messages">
                                {/*only if the height is fix*/}
                                {/*if call this.function, then no {}*/}
                                {chat_list != null ? chat_list.map((post, index) => (
                                    post.human ?
                                        this.self_post(post, index)
                                        :
                                        this.ai_post(post, index)
                                ))
                                    :
                                    <div></div>}


                                {/* Repeat similar structure for other messages */}
                                <div>
                                    {isMobile ? (
                                        <h1>This is shown on a smartphone</h1>
                                    ) : (
                                        <h1>This is not a smartphone</h1>
                                    )}
                                    {window.screen.width} <span/>
                                    {window.screen.height}
                                </div>
                            </div>

                        </div>

                        <div className="chat-input">
                            {/*to avoid automatic magnitude:  <input type="text" style={{ fontSize: '16px' }}*/}
                            <input type="text" style={{ fontSize: '16px' }} ref={this.sending_message} className="form-control" placeholder={dict_["type in the message here"]} onKeyDown={this.handleKeyPress.bind(this)} />
                            <button className="btn btn-primary" ref={this.sending_button} onClick={this.handle_sending.bind(this)}>{dict_["send"]} </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Talking;
