import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// all self-components
import Layout from './page/layout';
import SessionUser from '../components/session/new.js';
/**/
import NewUser from '../components/user/new.js';
import Logout from '../components/session/destroy.js';
import AccountSettings from '../components/user/accountSettings.js';

import Email from './email/email.js';

import Talking from './talking/talking.js';
// must start with capital letter


class Root extends Component {
    componentWillMount() {
        // This method will be called before the component is mounted
        // console.log('Component is about to mount');
    }

    handleClick = () => {
        // console.log('Component clicked!');
    };

    render() {
        // exact for identical match
        return (
            <Router>
                {/* Define your routes using Route , here must use element, not component*/}
                {/*this Route index will be visited automatically, even if visit other*/}
                <Routes>
                    <Route exact path="/" element={<Layout />}>
                        <Route index element={/*<Home />*/ <Talking />} />
                        <Route path="email" element={<Email />} />
                        <Route path="login" element={<SessionUser />} />
                        <Route path="signup" element={<NewUser />} />
                        <Route path="logout" element={<Logout />} />
                        <Route path="accountSettings" element={<AccountSettings />} />
                    </Route>
                    {/* prevent illegal return and make fetch head error*/}
                    <Route path="*" element={<Navigate to="/action/not_found" />} />
                </Routes>
            </Router>
        );

        /*
        
                        
        */
    }
}

export default Root;