import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import DeleteAccount from './destroy.js';
import UpdatePassword from './updatePassword.js';

import {dict_} from '../language.js';

class AccountSettings extends Component{

	//initially, no submission errors
	constructor(props) {
        super(props);

        this.state = {hasError: false, errorMsg: "", verified: false, verificationMessage: ""};
	
		this.current_password = React.createRef();

		this.gmail_address = React.createRef();
		// can't use it twice, otherwise override...missing value

		this.website = React.createRef();
    }

	verifyPassword(e){
		/*
		var that = this;
		if(this.refs.current_password.value){
			var user = firebase.auth().currentUser;
			var credential = firebase.auth.EmailAuthProvider.credential(user.email, this.refs.current_password.value);
			user.reauthenticate(credential).then(function(){
				that.setState({verified: true});
				that.setState({verificationMessage: "Your password has been verified."});
				that.setState({hasError: false});
				that.setState({errorMsg: ""});
			}).catch(function(error){
				that.setState({hasError: true});
				that.setState({errorMsg: "The password you entered is invalid."});
				that.setState({verified: false});
				that.setState({verificationMessage: ""});
			});
		}else{
			this.setState({hasError: true});
			this.setState({errorMsg: "Your current password cannot be blank."});
			this.setState({verified: false});
			this.setState({verificationMessage: ""});
		}
		*/

		// connect to database
		if(this.current_password.current.value){
			const json = {
				"action": "account",
				"ops": "verify" ,
				"typed_password": this.current_password.current.value
			};

			const formData = new URLSearchParams();
			for (const key in json) {
				formData.append(key, json[key]);
			}
			fetch("/action/", {
				method: "POST",
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: formData // must stringify
			})
				.then((response) => response.json())
				.then((data) => {
					// console.log(data);
					console.log({ ...json, ...data });
					if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
						this.setState({verified: true, 
							verificationMessage: "您的密码已经被验证.",
							hasError: false,
							errorMsg: ""});
					} else{
						this.setState({hasError: true,
							errorMsg: "您输入的密码无效.",
							verified: false,
							verificationMessage: ""});
					}
				})
				.catch((error) => {
					console.error("网络错误 :", error);
				});

		} else {
			this.setState({hasError: true, 
				errorMsg: "您当前的密码不能为空白.",
				verified: false,
				verificationMessage: ""});
		}
	}

	handleTypingChange(){
		this.setState({verified: false});
		this.setState({hasError: false});

		
	}

	handleKeyPress(e){
		if(e.key == "Enter"){
			try{
				this.verifyPassword();
			}catch(e){}
		}
	}

	//creates an empty div if no error message
	enterPasswordAlert(){
		return <div className="alert alert-info"> please verify your password first </div>;;
	} // 请操作前先输入您的密码验证.

	successAlert(){
		return <div className="alert alert-success"><strong> verified ! </strong>{this.state.verificationMessage}</div>;
	} // 验证成功 !

	//creates an empty div if no error message
	errorAlert(){
		return <div className="alert alert-danger"><strong> password error </strong>{this.state.errorMsg}</div>;
	} // 




	change_email(mailbox_type, email_address){
		// email_address==null  // default
        const json = {
            "action": "email",
            "ops": "change_email",
			"mailbox_type": mailbox_type,
            "email_address": this.gmail_address.current.value
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }

        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
				if ("ifsuccess" in data){
					if (data["ifsuccess"] == "wait for login"){
						console.log("open new url", data['url']);
						window.open(data['url'], '_top', 'width=800,height=600');  
						// some safari block pop-ups, have to use redirection, thus _top instead of _blank
					}
				}

            })
            .catch((error) => {
                console.error("Error to change email address:", error);
            });
	}

	render(){
		//gets the appropriate error alert div depending on whether or not the form has an error
		var alert;
		if(this.state.verified){
			alert = this.successAlert();
		}else if(this.state.hasError){
			alert = this.errorAlert();
		}else{
			alert = this.enterPasswordAlert();
		}

		var show;
		if(this.state.verified){
			show = 
				<div>
					<UpdatePassword /><br />
					{/*<DeleteAccount /><br />*/}
				</div>
		}else{ // 当前密码
			show = 
				<div>
					<input type="password" ref={this.current_password} placeholder="current password" className="form-control" onChange={this.handleTypingChange.bind(this)} onKeyDown={this.handleKeyPress.bind(this)}/><br />
					<button className="btn btn-success" onClick={this.verifyPassword.bind(this)}>verify</button>
				</div> 
		} // 验证

		return(
			<div className="container">
				<center>
					<h1>settings</h1><br />
				</center>

				<div className="col-md-3">
					<center>
						<h3>change password</h3>
					{alert}
					{show}
					</center>
				</div>
				<div className="col-md-4">
					<center>
						<h3>change email box</h3>
						<p> emailhelp mailbox(default)
						<span>   </span>
						<button className="btn btn-success" onClick={this.change_email.bind(this, "default")}>use it</button>
						</p>
						<p> gmail
						<input type="text" ref={this.gmail_address}/> 
						<span>   </span>
						<button className="btn btn-success" onClick={this.change_email.bind(this, "gmail")}>use it</button>
						</p>{/*this.change_email.bind(this, "gmail", this.gmail_address.current? this.gmail_address.current.value:"")  can't do it, already valued gmail_address as ""*/}
					</center>

					<center>
						<h3>change website</h3>
						<input type="text" ref={this.website}/> 
						<span>   </span>
						<button className="btn btn-success" onClick={this.change_email.bind(this, "gmail")}>use it</button>
					</center>
				</div>
				<div className="col-md-4">
				</div>
			</div>
		);
	}
};

export default AccountSettings;